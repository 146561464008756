import { graphql } from 'gatsby'
import React from 'react'
import AccordionBlock from 'components/AccordionBlock'
import Banner from 'components/Banner'
import Breadcrumb from 'components/Breadcrumb'
import Body from 'components/Body'
import PostList from 'components/PostList'
import Footer from 'components/Footer'
import { tr } from 'js/translations.js'
import { useLocalization } from 'gatsby-theme-i18n'
import SEO from 'components/SEO'

const Page = ({ data }) => {
  const { locale } = useLocalization()
  const page = data[`page_${locale}`]
  const trainings = data[`trainings_${locale}`].nodes

  const breadcrumb = [
    {
      text: tr('HOME', locale),
      link: '/',
    },
    {
      text: page.title,
    },
  ]

  return (
    <div className='page'>
      <div>
        <SEO page={page} />
        <Banner content={page.banner} />
        <Breadcrumb items={breadcrumb} />
        <Body body={page.body} />
        <PostList
          content={trainings}
          category='training'
          showListBg={true}
          showButtonLinks={true}
        />
        <Footer />
      </div>
    </div>
  )
}

export default Page

export const pageQuery = graphql`
  query {
    page_en: contentfulPage(
      slug: { eq: "training" }
      node_locale: { eq: "en-US" }
    ) {
      id
      title
      seo {
        title
        description
      }
      banner {
        pretitle
        title
        subtitle
      }
      body {
        ... on ContentfulBlockIntro {
          __typename
          id
          image {
            ...GatsbyImage
          }
          caption
          title
          body {
            raw
          }
        }
        ... on ContentfulBlockIntroExtended {
          __typename
          imageText1 {
            image {
              ...GatsbyImage
            }
            title
            body {
              raw
            }
            link
          }
          imageText2 {
            image {
              ...GatsbyImage
            }
            title
            body {
              raw
            }
            link
          }
          image {
            image {
              ...GatsbyImage
            }
            caption
          }
        }
        ... on ContentfulBlockCenterText {
          __typename
          id
          title
          body {
            raw
          }
          isLeftAligned
        }
        ... on ContentfulBlockImageText {
          __typename
          id
          image {
            ...GatsbyImage
          }
          caption
          title
          body {
            raw
          }
        }
        ... on ContentfulBlockContact {
          __typename
          phone
          email
          address {
            raw
          }
          googleMapLink
          baiduMapLink
          image {
            ...GatsbyImage
          }
        }
      }
    }
    page_zh: contentfulPage(
      slug: { eq: "training" }
      node_locale: { eq: "zh-CN" }
    ) {
      id
      title
      seo {
        title
        description
      }
      banner {
        pretitle
        title
        subtitle
      }
      body {
        ... on ContentfulBlockIntro {
          __typename
          id
          image {
            ...GatsbyImage
          }
          caption
          title
          body {
            raw
          }
        }
        ... on ContentfulBlockIntroExtended {
          __typename
          imageText1 {
            image {
              ...GatsbyImage
            }
            title
            body {
              raw
            }
            link
          }
          imageText2 {
            image {
              ...GatsbyImage
            }
            title
            body {
              raw
            }
            link
          }
          image {
            image {
              ...GatsbyImage
            }
            caption
          }
        }
        ... on ContentfulBlockCenterText {
          __typename
          id
          title
          body {
            raw
          }
          isLeftAligned
        }
        ... on ContentfulBlockImageText {
          __typename
          id
          image {
            ...GatsbyImage
          }
          caption
          title
          body {
            raw
          }
        }
        ... on ContentfulBlockContact {
          __typename
          phone
          email
          address {
            raw
          }
          googleMapLink
          baiduMapLink
          image {
            ...GatsbyImage
          }
        }
      }
    }
    trainings_en: allContentfulTraining(
      filter: { node_locale: { eq: "en-US" } }
      sort: { fields: order, order: ASC }
    ) {
      nodes {
        slug
        title
        excerpt
        dates
        isFeatured
        imageThumb {
          ...GatsbyImage
        }
      }
    }
    trainings_zh: allContentfulTraining(
      filter: { node_locale: { eq: "zh-CN" } }
      sort: { fields: order, order: ASC }
    ) {
      nodes {
        slug
        title
        excerpt
        dates
        isFeatured
        imageThumb {
          ...GatsbyImage
        }
      }
    }
  }
`

// teachers_en: allContentfulTeacher(
//   filter: { node_locale: { eq: "en-US" } }
//   sort: { fields: order, order: ASC }
// ) {
//   nodes {
//     title
//     description {
//       raw
//     }
//     imagePortrait {
//       ...GatsbyImage
//     }
//   }
// }
// teachers_zh: allContentfulTeacher(
//   filter: { node_locale: { eq: "zh-CN" } }
//   sort: { fields: order, order: ASC }
// ) {
//   nodes {
//     title
//     description {
//       raw
//     }
//     imagePortrait {
//       ...GatsbyImage
//     }
//   }
// }
