import React from 'react'
import Swoosh from 'components/Swoosh'
import ImageWrap from 'components/ImageWrap'
import Inview from 'components/Inview'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

const Component = ({ title, content, hasBg }) => {
  return (
    <div className={`component-accordion-block ${hasBg ? 'has-bg' : ''}`}>
      <div className='inner'>
        <Inview className='fade-in'>
          <h2>{title}</h2>
        </Inview>
        <Accordion content={content} />
      </div>
      {!hasBg && (
        <div className='swoosh-wrap'>
          <Swoosh left='-10%' width='140%' opacity='0.04' top='25%' />
        </div>
      )}
    </div>
  )
}

export default Component

class Accordion extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeIndex: 0,
    }
    this.setActiveIndex = this.setActiveIndex.bind(this)
  }

  setActiveIndex = (index) => {
    if (index === this.state.activeIndex) {
      // close existing
      this.setState({
        activeIndex: null,
      })
    } else {
      this.setState({
        activeIndex: index,
      })
    }
  }

  render() {
    const { content } = this.props
    return (
      <div className='accordion'>
        {content.map((item, i) => (
          <Item
            key={i}
            content={item}
            index={i}
            activeIndex={this.state.activeIndex}
            setActiveIndex={this.setActiveIndex}
          />
        ))}
      </div>
    )
  }
}

const Item = ({ content, index, activeIndex, setActiveIndex }) => {
  return (
    <Inview
      className={`item
      ${index === activeIndex ? 'active' : ''}
      fade-in
    `}
      onClick={() => setActiveIndex(index)}
    >
      <h4>{content.title}</h4>
      <div className='body'>
        {content.imagePortrait && (
          <div className='image'>
            <ImageWrap image={content.imagePortrait} />
          </div>
        )}
        <div className='text'>
          {content.description && renderRichText(content.description)}
        </div>
      </div>
    </Inview>
  )
}
